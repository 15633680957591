import React from "react";
import { useCookies } from "react-cookie";
import InputPassword from "../form-inputs/password-input.component";
import InputUser from "../form-inputs/user-input.component";
import { Button, makeStyles } from "@material-ui/core";
import { Form, Field, Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import * as authTypes from "../../model/authTypes";

const key = "auth-type";

const LoginForm = ({
	config,
	authType,
	setLoading,
	setErrorMessage,
	redirectToWeb,
}) => {
	const [, setCookie] = useCookies(["token"]);

	const classes = useStyles();
	const saveAuthToLS = (authType) => {
		localStorage.setItem(key, authType);
	};
	const requestToken = async (values) => {
		let url = "";
		switch (authType) {
			case authTypes.LDAP:
				url = config.LOGIN.AUTH_LDAP_URI;
				break;
			default:
				url = config.LOGIN.AUTH_URI;
				break;
		}
		return await axios
			.post(url, values, {
				timeoutErrorMessage: "El servidor tardó demasiado en responder",
				timeout: 10000,
			})
			.then(
				(response) => {
					if (response?.status === 200 || response?.status === 204)
						return response;
					else {
						let error = new Error(
							`Error ${response?.status ?? ""}: ${
								response?.statusText ?? "-"
							}`
						);
						error.response = response;
						throw error;
					}
				},
				(error) => {
					let message = "";

					if (!error?.response?.status) {
						throw new Error("Error de conexión");
					}

					switch (error.response.status) {
						case 401:
						case 404:
							message =
								"Usuario, contraseña o método de autenticación incorrectos";
							break;
						default:
							message = error.message;
							break;
					}

					const errMess = new Error(message);
					throw errMess;
				}
			)
			.then((response) => response.data)
			.catch((error) => {
				setErrorMessage(error.message);
				setLoading(false);
			});
	};

	return (
		<div className="row no-gutters w-100">
			<Formik
				initialValues={{
					username: "",
					password: "",
				}}
				validationSchema={Yup.object({
					username: Yup.string()
						.required("Campo Obligatorio")
						.min(2, "Debe tener al menos 2 caracteres"),
					password: Yup.string().required("Campo Obligatorio"),
				})}
				onSubmit={(values, { setSubmitting }) => {
					setErrorMessage(null);
					setLoading(true);
					setSubmitting(false);
					saveAuthToLS(authType);
					setTimeout(() => {
						requestToken(values).then((res) => {
							if (res) {
								setCookie("token", res.token, {
									path: "/",
									// sameSite: "none",
									// secure: true,
								});
								redirectToWeb();
							}
						});
					}, 1000);
				}}
			>
				<Form className="w-100 h-100">
					<div className="row no-gutters w-100">
						<Field
							variant="outlined"
							fullWidth
							label="Usuario"
							name="username"
							autoComplete="true"
							component={InputUser}
						/>
					</div>

					<div className="row no-gutters w-100 pt-2">
						<Field
							variant="outlined"
							fullWidth
							label="Contraseña"
							name="password"
							autoComplete="true"
							component={InputPassword}
						/>
					</div>
					<div
						className="d-flex align-items-center justify-content-center"
						style={{ paddingTop: "25px" }}
					>
						<Button
							type="submit"
							variant="contained"
							autoFocus
							disableFocusRipple
							className={classes.button}
						>
							<span className="font-weight-bold">ENTRAR</span>
						</Button>
					</div>
				</Form>
			</Formik>
		</div>
	);
};

export default LoginForm;

const useStyles = makeStyles((theme) => ({
	button: {
		color: theme.palette.primary.contrastText,
		backgroundColor: theme.palette.primary.main,
		boxShadow: "none !important",
		width: "38%",
		position: "fixed !important",
		bottom: "25px",
		"&:hover": {
			color: theme.palette.primary.contrastText,
			backgroundColor: theme.palette.primary.dark,
		},
	},
}));
